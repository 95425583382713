<template>
    <div class="pb-4">
        <div ref="newsCard" class="news_card">
            <div class="created_data">
                <i class="fi fi-rr-calendar"></i> {{ $moment(item.created_at).format('DD MMM YYYY г.') }}
            </div>
            <h2>
                <span v-if="item.is_important" class="important">
                    <img src="../../assets/img/fire.svg" />
                </span>
                {{ item.title }}
            </h2>
            <div v-if="moreText" class="main_text">
                <div class="more_wrapper" :class="showText && 'show'">
                    <TextViewer :body="item.content" />
                    <div v-if="!showText" class="more_wrapper__btn">
                        <a-button 
                            type="link" 
                            flaticon 
                            icon="fi-rr-angle-small-down" 
                            size="small" 
                            @click="showText = true">
                            {{ $t('support.expand') }}
                        </a-button>
                    </div>
                </div>
            </div>
            <div v-else class="main_text">
                <TextViewer :body="item.content" />
            </div>
            <div class="flex items-center mt-3 like_actions">
                <a-badge :count="taskVote.likes_count" :number-style="{ backgroundColor: '#52c41a' }">
                    <a-button 
                        type="ui" 
                        flaticon 
                        shape="circle"
                        icon="fi-rr-social-network" 
                        :class="{ 'blue_color': myVote === 'like'}" 
                        @click="vote('like')" />
                </a-badge>
                <a-badge :count="taskVote.dislikes_count">
                    <a-button 
                        type="ui" 
                        class="ml-2"
                        flaticon 
                        shape="circle"
                        icon="fi-rr-hand" 
                        :class="{ 'text_red': myVote === 'dislike'}"
                        @click="vote('dislike')" />
                </a-badge>
            </div>
        </div>
    </div>
</template>

<script>
import TextViewer from '@apps/CKEditor/TextViewer.vue'
import eventBus from '@/utils/eventBus'
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        setNewsRead: {
            type: Function,
            default: () => {}
        }
    },
    components: {
        TextViewer
    },
    computed: {
        myVote() {
            if(this.taskVote.my_vote === null)
                return null
            if(this.taskVote.my_vote)
                return 'like'
            return 'dislike'
        },
        moreText() {
            return this.item.short_content.length > 400 ? true : false
        }
    },
    data() {
        return {
            taskVote: {},
            taskVoteLoading: false,
            visible: false,
            readLoading: false,
            showText: false
        }
    },
    created() {
        this.getVote()
        if(!this.item.has_read && !this.readLoading) {
            this.openFullNews()
        }
    },
    methods: {
        async vote(choice) {
            let boolChoice,
                fieldToVote,
                oppositeFieldToVote
            if (choice === 'like') {
                fieldToVote = 'likes_count'
                oppositeFieldToVote = 'dislikes_count'
                boolChoice = true
            } else if (choice === 'dislike') {
                fieldToVote = 'dislikes_count'
                oppositeFieldToVote = 'likes_count'
                boolChoice = false
            }
            const payload = {
                vote: boolChoice
            }

            await this.$http.post(`vote/${this.item.id}/`, payload)
                .then(() => {
                    if(this.taskVote.my_vote !== null) {
                        if(this.taskVote.my_vote === boolChoice) {
                            this.taskVote[fieldToVote] += -1
                            this.taskVote.my_vote = null
                        } else {
                            this.taskVote[oppositeFieldToVote] += -1
                            this.taskVote[fieldToVote] += 1
                            this.taskVote.my_vote = boolChoice
                        }
                    } else {
                        this.taskVote[fieldToVote] += 1
                        this.taskVote.my_vote = boolChoice
                    }
                })
                .catch(error => console.error(error))
        },
        async getVote() {
            try {
                this.taskVoteLoading = true
                const { data } = await this.$http.get(`vote/${this.item.id}/`)
                this.taskVote = data
            } catch(e) {
                console.log(e)
            } finally {
                this.taskVoteLoading = false
            }
        },
        async openFullNews() {
            try {
                this.readLoading = true
                const { data } = await this.$http.get(`/news/news/${this.item.id}/`)
                if(data) {
                    this.setNewsRead(this.item.id)
                    eventBus.$emit('read_news_count')
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.readLoading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.like_actions{
    &::v-deep{
        .ant-badge-count{
            min-width: 15px;
            height: 15px;
            line-height: 15px;
            top: 4px;
            right: 2px;
            font-size: 10px;
            &.ant-badge-multiple-words{
                padding: 0 4px;
            }
            .ant-scroll-number-only{
                height: 15px;
                p{
                    height: 15px;
                }
            }
        }
        .ant-badge-count{
            font-size: 10px !important;
            min-width: 17px;
            height: 17px;
            padding: 0 6px;
            line-height: 17px;
        }   
    }
}
.news_card{
    border: 1px solid var(--border2);
    border-radius: var(--borderRadius);
    padding: 15px;
    background: #ffffff;
    @media (min-width: 768px) {
        padding: 20px 30px; 
    }
    h2{
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 15px;
        line-height: 24px;
        display: flex;
        align-items: baseline;
        word-break: break-word;
        .important{
            margin-right: 6px;
            min-width: 15px;
            @media (min-width: 768px) {
                min-width: 20px;
            }
            img{
                max-width: 15px;
                @media (min-width: 768px) {
                    max-width: 20px;
                }
            }
        }
        @media (min-width: 768px) {
            font-size: 20px;
            line-height: 26px;
        }
    }
    .created_data{
        color: var(--gray);
        font-weight: 300;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        i{
            margin-right: 10px;
        }
    }
    .main_text{
        &::v-deep{
            font-size: 16px;
            line-height: 26px;
        }
        .more_wrapper{
            &:not(.show){
                max-height: 150px;
                overflow: hidden;
                position: relative;
                .more_wrapper__btn{
                    position: absolute;
                    height: 50px;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    z-index: 10;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+58 */
                    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 65%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                }
            }
        }
    }
}
</style>